import axios from 'axios'
import {notify} from "../../components/Notify";
import {nodeFromString} from "../../tools/NodeFromString";
import datetimePicker from "../../components/DatetimePicker";

export default function sanctionHistory() {
    let container = document.querySelector('.sanction-histories-container')

    if (container) {
        let list = container.querySelector('.sanction-histories'),
            count = container.querySelectorAll('.sanction-history').length

        function initItem(item, isNew = false) {
            item.querySelector('.sanction-history-remove')?.addEventListener('click', e => {
                e.preventDefault()
                item.remove()
            })

            if (isNew === true) {
                item.querySelectorAll('[datetimepicker]').forEach(input => {
                    datetimePicker(input)
                })

                $(item).find('[data-toggle="select"]').select2({
                    allowClear: false,
                    minimumResultsForSearch: 5
                })

                $(item).find('[role="tablist"]').tab('show')
            }
        }

        function initAdd() {
            container.querySelector('.sanction-histories-add')?.addEventListener('click', e => {
                e.preventDefault()
                axios.get(`/admin/sanctions/history?index=${++count}`)
                    .then(result => {
                        let item = nodeFromString(result.data.data)
                        initItem(item, true)
                        list.append(item)
                    })
                    .catch(error => {
                        notify('Error', 'danger', error)
                    })
            })
        }

        initAdd()
        container.querySelectorAll('.sanction-history').forEach(initItem)
    }
}

